<template>
  <div>
    <el-row>
      <el-col>
        <p class="content-text">
          <span style="color: teal; font-weight: bold;">Full paper authors are required to register by 7 August</span>.
        </p>
      </el-col>
    </el-row>

    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Registration is Now Open! </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          Don’t miss your chance to join us in Melbourne, Australia this October for the 32nd ACM Multimedia Conference.
        </p>
      </el-col>
      <el-col>
        <p class="content-text">
          Witness the powerful keynotes, latest research and reconnect with your multimedia colleagues from across the world.
        </p>
      </el-col>
      <el-col>
        <p class="content-text">
          Registration has been opened early to allow those travelling from overseas ample time to complete the online registration form and receive necessary visa support documents.
        </p>
      </el-col>
      <el-col>
        <p class="content-text">
          Full paper authors are required to register by <b>7 August</b> and Workshops paper authors must register before <b>22 August</b>. A recent email saying the full paper registration deadline is 19 July, which is a mistake. Apologies for that.
        </p>
      </el-col>
      <el-col>
        <div class="vic_button">
          <button onclick=" window.open('/registration')">Register Today</button>
      </div>
      </el-col>
    </el-row>
    
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Welcome our MM 24 Keynote Speakers </p>
      </el-col>
    </el-row>
    <table border="1">
      <tr align="center">
        <td style="width: 33.33%"><img :src="require('@/assets/keynotes/Pascale_Fung.png')" alt="Prof Pascale Fung" style="height: 200px;"></td>
        <td style="width: 33.33%"><img :src="require('@/assets/keynotes/Judy_Kay.png')" alt="Prof Judy Kay" style="height: 200px;"></td>
        <td style="width: 33.33%"><img :src="require('@/assets/keynotes/Jiebo_Luo.png')" alt="Prof Jiebo Luo" style="height: 200px;"></td>
      </tr>
      <tr align="center">
          <td><strong>Prof Pascale Fung</strong></td>
          <td><strong>Prof Judy Kay</strong></td>
          <td><strong>Prof Jiebo Luo</strong></td>
        </tr>
        <tr class="align-top" align="center">
          <td><strong>The Hong Kong University of Science & Technology (HKUST)</strong><br>Hong Kong</td>
          <td><strong>University of Sydney</strong><br>Australia</td>
          <td><strong>University of Rochester</strong><br>USA</td>
        </tr>
    </table>

    <table border="1" style="width: 50%;" align="center">
      <tr align="center">
        <td><img :src="require('@/assets/keynotes/Benoit_HUET.jpg')" alt="Dr Benoit HUET"></td>
      </tr>
      <tr align="center">
        <td><strong>Dr Benoit HUET</strong></td>
      </tr>
      <tr class="align-top" align="center">
        <td>MEDIAN Technologies<br>France</td>
      </tr>
    </table>

    <el-row>
      <el-col>
        <div class="vic_button">
          <button onclick=" window.open('/keynotes')">Find Out More</button>
      </div>
      </el-col>
    </el-row>

    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Upcoming Deadlines </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <strong>Please note:</strong> <i>The submission deadline is at 11:59 p.m. (Anywhere on Earth) of the stated deadline date.</i>
        </p>
        <!-- Workshop Papers Submission deadline: July 19, 2024
        Regular Paper author registration deadline: July 29, 2024
        Regular Paper Camera-ready Submission deadline: July 29, 2024
        All other Camera-ready Submissions deadline: July 29, 2024
        Industry Demo Abstract Submission Deadline: July 31, 2024
        Industry Expert Talks Proposal deadline July 31, 2024 -->
        <!-- <p class="content-text">Open Source Competition Submission deadline: <span style="color: red;">June 30, 2024</span></p>
        <p class="content-text">Panel Proposal Submission deadline: <span style="color: red;">June 3, 2024</span></p>
        <p class="content-text">Tutorial Proposal Submission deadline: <span style="color: red;">June 12, 2024</span></p>
        <p class="content-text">Doctoral Symposium deadline: <span style="color: red;">June 3, 2024</span></p>
        <p class="content-text">Technical Demo Video Program Submission deadline: <span style="color: red;">June 3, 2024</span></p>
        <p class="content-text">Reproducibility Companion Submission deadline: <span style="color: red;">June 3, 2024</span></p> -->
        <!-- <p class="content-text">Workshop Papers Submission deadline: <span style="color: red;">July 19, 2024</span></p> -->
        <p class="content-text">Main Proceedings Author Registration & Camera-ready Submission Deadline: <span style="color: red;">August 7, 2024</span></p>
        <!-- <p class="content-text">Regular Paper Camera-ready Submission deadline: <span style="color: red;">July 29, 2024</span></p>
        <p class="content-text">All other Camera-ready Submissions deadline: <span style="color: red;">July 29, 2024</span></p>
        <p class="content-text">Industry Demo Abstract Submission Deadline: <span style="color: red;">July 31, 2024</span></p> -->
        <!-- <p class="content-text">Industry Expert Talks Proposal deadline: <span style="color: red;">July 31, 2024</span></p> -->
        <p class="content-text">Companion Proceedings (Workshops, Grand Challenges, Tutorials) Camera-ready Submission Deadline: <span style="color: red;">August 22, 2024</span></p>
      </el-col>
      <el-col>
        <div class="vic_button">
          <button onclick=" window.open('/important-dates')">More Important Dates</button>
        </div>
      </el-col>
    </el-row>

    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Highlights of ACM Multimedia 2024 </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Scientific Diversity</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          It is among our top priorities to ensure a top-quality conference that covers a full diversity of all fields of multimedia research including a variety of media modalities by addressing both technological and practical challenges.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Value</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          We strive to select the most innovative and the highest-quality research aiming at the most impactful novelty on individual media and/or from a systematic perspective of innovating and integrating multiple components across modalities.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Sharing</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          We value the sharing of not only knowledge (in the form of papers, presentations and demos) but also the code and open-source software. While we will continue the implementation of the ACM Artifact Review and Badging, best efforts will be made on sharing and preserving the open-source code and systems resulting from the published papers.
        </p>
      </el-col>
    </el-row>



    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Express Your Interest to Attend ACMMM 2024 </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          If you are interested in attending, presenting, sponsoring or exhibiting at ACMMM 2024, please complete the Expression of Interest form using the link below to be updated on all key announcements.
        </p>
      </el-col>
      <el-col>
        <div class="vic_button">
          <button onclick=" window.open('https://icmsaust.eventsair.com/acmmm-2024---32nd-acm-international-conference-on-multimedia/eoi-form/Site/Register','_blank')">COMPLETE THE EOI FORM</button>
      </div>
      </el-col>
      <el-col class="qr_text">
        <p>QR code to the EOI form:</p>
      </el-col>
      <el-col class="contact_image">
        <img  src="@/assets/images/register_qr.png" alt="register QR code">
      </el-col>
    </el-row>

    
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> News and Updates </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">27/07/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>Student travel grant</b> applications (<a class="demo-link" href="/travel_grants">page link</a>) are open until <b>August 9, 2024</b>. Notifications will be sent on <b>August 16, 2024</b>.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">16/07/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          Accepted paper list is available at <a class="demo-link" href="/files/accepted_papers.txt">here</a>.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">12/06/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          The reviews for the ACM Multimedia main conference papers were released on June 10th. We extend our sincere gratitude to the entire TPC team, Program Co-chairs, Associate Chairs, and all reviewers, especially those who worked over the weekends and on the public holiday of June 10th. Remarkably, we achieved 100% on-time reviews for a total of 4,340 papers, each receiving at least three reviews. Congratulations to the entire TPC team for their outstanding accomplishment in completing this tremendous work!
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">06/04/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          The main conference abstract is due in less than 3 day (Apr. 8 AoE), and the paper submission is due in less than one week (Apr. 12 AoE). The brave new idea (BNI) track follows the same deadline. Please note that these main conference deadlines will NOT be extended.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">05/04/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>[Important] Major differences</b> between MM'24 and previous MM. (<a class="demo-link" href="/major_differences">here</a>)
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">04/04/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>Paper Submission Link</b> is now available <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Conference">here</a>.<br>
          <b>Brave New Idea Submission Link</b> is now available <a class="demo-link" href="https://openreview.net/group?id=acmmm.org/ACMMM/2024/Track/BNI">here</a>.<br>
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">10/02/2024</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>Call for Brave New Ideas</b> is now available <a class="demo-link" href="/files/MM24_BNI.pdf" target="_blank">here</a>.<br>
          <b>Call for Grand Challenge Proposals</b> is now available <a class="demo-link" href="/files/MM24_Grand_Challenge.jpg" target="_blank">here</a>.<br>
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">27/11/2023</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>Call for Volunteer Area Chairs and Reviewers</b> is now available <a class="demo-link" href="/volunteer_ac" target="_blank">here</a>.<br>
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">01/10/2023</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>Call for Paper flyer</b> is now available <a class="demo-link" href="/files/ACMMM24_CFP.pdf" target="_blank">here</a>.<br>
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">30/08/2023</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          Calls for Submission added.<br>
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">20/07/2023</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b style="color: black">Please note</b>: The submission deadline is at 11:59 p.m. (<a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>) of the stated deadline date.<br>
          <span> <span style="color:black;font-weight: 600">Grand Challenge Proposals</span> deadline: <span
              style="color:red;">February 19, 2024. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Workshop Proposals</span> submission deadline: <span
              style="color:red;">March 4, 2024. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Regular Papers</span> submission deadline: <span
              style="color:red;">April 12, 2024. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Brave New ldeas</span> submission deadline: <span
              style="color:red;">April 12, 2024. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Doctoral Symposium</span> deadline: <span
              style="color:red;">June 3, 2024.</span></span><br>
          <span> <span style="color:black;font-weight: 600">Industrial Panel</span> deadline: <span
              style="color:red;">June 3, 2024.</span></span><br>
          <!-- <span> <span style="color:black;font-weight: 600">Grand Challenge Solutions</span> deadline: <span
              style="color:#05cedd;">July 8, 2024.</span></span><br> -->
          
        </p>
      </el-col>
    </el-row>

    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Contact Us</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="content-text">
        If you have any question related to ACM Multimedia 2024, please find the appropriate contact <a class="demo-link" href="/contact" target="_blank">here</a>. <br>
        </p>
      </el-col>
    </el-row>

    <!-- <el-row>
      <el-col>
        <p class="content-text">
          For any <span style="color:black;font-weight: 600">delegate, sponsor</span> or <span style="color:black;font-weight: 600">exhibitor</span> who are interested in ACM Multimedia 2024, please feel free to contact us by this <a class="demo-link" href="https://icmsaust.eventsair.com/acmmm-2024---32nd-acm-international-conference-on-multimedia/eoi-form/Site/Register" target="_blank">link</a>.<br>
        </p>
      </el-col>
      <el-col>
        <img class="contact_image" src="@/assets/images/register_qr.png" alt="register QR code">
      </el-col>
    </el-row> -->

    <!--第二部分-->
    <!-- <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Introduction </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Welcome to the 29th ACM International Conference on Multimedia.</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> Since the founding of ACM SIGMM in 1993, ACM Multimedia has been the worldwide
          premier conference and a key world event to display scientific achievements and innovative industrial
          products in the multimedia field. For the first time in its history, ACM Multimedia 2021 will be held in
          Chengdu, the capital city of the Sichuan Province in China. At ACM Multimedia 2021, we will present an
          extensive program consisting of technical sessions covering all aspects of the multimedia field via
          oral, video and poster presentations, tutorials, panels, exhibits, demonstrations, workshops, doctoral
          symposium, multimedia grand challenge, brave new ideas on shaping the research landscape, open source
          software competition, and also an interactive arts program stimulating artists and computer scientists
          to meet and discover together the frontiers of artistic communication. We will also add the industrial
          track to recognize those research works with significant industrial values.</p>
      </el-col>
    </el-row> -->
    <!--第三部分-->
<!--     <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Important Dates </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <img class="time-line-pc" :src="timeLinepc" alt="">
        <img class="time-line-modie" :src="timeLineModie">
      </el-col>
    </el-row> -->
    <!--第四部分-->
    <el-row class="el-rows" > 
    <!-- <el-row class="primaryTitle" > -->
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="black" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
      </el-col>
      <el-col :span="22">
        <p class="title"> Research Topics </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> <span style="color:black;font-weight: 600">ACM Multimedia 2024, Multimedia in the Generative AI Era</span>, calls for research papers presenting novel theoretical and
          algorithmic solutions addressing problems across the domain of multimedia and related applications. The
          conference also calls for papers presenting novel, thought-provoking ideas and promising (preliminary)
          results in realizing these ideas. Submissions are invited in the following 4 themes containing 12
          areas:</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Engaging Users with Multimedia</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Emotional and Social Signals</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimedia Search and Recommendations </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Summarization, Analysis and Storytelling </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Experience</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Interactions and Quality of Experiences </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Art and Culture </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimedia Applications </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Multimedia Systems</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Systems and Middleware </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Transport and Delivery </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Data Systems Management and Indexing </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Multimedia Content Understanding</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimodal Fusion</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Vision and Language </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimedia Interpretation</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Multimedia in the Generative AI Era</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimedia Foundation Models</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Generative Multimedia </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Social Aspects of Generative AI</p>
      </el-col>
    </el-row>
    <!--第五部分-->
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="black" class="simple_bullet_point" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"/>
</svg> -->
      </el-col>
      <el-col :span="22">
        <p class="title"> Highlights of ACM Multimedia 2024 </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Scientific Diversity</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> It is among our top priorities to ensure a top-quality conference that covers a
          full diversity of all fields of multimedia research including a variety of media modalities by addressing
          both technological and practical challenges. </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Value</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> We strive to select the most innovative and the highest-quality research aiming at
          the most impactful novelty on individual media and/or from a systematic perspective of innovating and
          integrating multiple components across modalities.</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Sharing</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> We value the sharing of not only knowledge (in the form of papers, presentations
          and demos) but also the code and open-source software. While we will continue the implementation of the
          ACM Artifact Review and Badging, best efforts will be made on sharing and preserving the open-source code
          and systems resulting from the published papers. </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "context",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      timeLinepc: "http://cdn.acmmm.hchyun.com/sy_sjz2x02.png",
      timeLineModie: 'http://cdn.acmmm.hchyun.com/sy_sjz2x02.png',
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.el-rows {
  /* todo */
  margin-top: 3rem;
  margin-bottom: 2rem;
}

p {
  margin: 0;
}

a {
  /* color: #05cedd;  */
  color: red;
}

.vic_button{
    /* display: inline-block; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.vic_button > button{
    border: none;
    padding: 1.1rem 5rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    background: #2C9370;
    color: #FFFFFF;
    /* border-radius: 1rem; */
}

.qr_text{
  /* display: inline-block; */
/*    margin-top: 1rem;
    margin-bottom: 1rem;*/
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.qr_text > p{
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.time-line-pc {
  width: 100%;
}

.time-line-modie {
  width: 100%;
}

.icon {
  margin-top: .8rem;
  margin-right: 1rem;
  width: 3.4rem;
}

.contact_image{
  display: flex;
  flex-direction: row;
  justify-content:center;
  /* align-content: center; */
  align-items: center;
  /* background: #05cedd; */
  text-align: center;
  /* height: 50%; */
}

.contact_image > img{
  /*padding-top: 2rem;*/
  width: 110px;
}

.dot {
  width: 1rem;
  height: 1rem;
  margin-top: .5rem;
  /*background-color: #0054B0;*/
  /*background-color: #2e686f;*/
  /* background-color: #05cedd; */
  background-color: black;
  border-radius: .5rem
}

.title {
  margin-left: -1.8rem !important;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  /*color: #202020;*/
  color: black;
}

@media screen and (max-width: 1920px) {
  .title {
    margin-left: 0;
  }

  .time-line-pc {
    display: none;
  }

  .time-line-modie {
    display: block;
  }
}

@media screen and (min-height: 750px) {
  .time-line-pc {
    display: block;
  }

  .time-line-modie {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .title {
    margin-left: 1rem !important;
  }

  .icon {
    margin-top: .4rem;
  }

  .time-line-pc {
    display: none;
  }

  .time-line-modie {
    display: block;
  }
}

.headset {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: .4rem;
  /*color: #0054B0;*/
  /*color: #2e686f;*/
  /*color: #08b48c;*/
  /* color: #05cedd; */
  color: black;
}

.content-text {
  text-align: justify;
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #202020;
  /*color: #04b0b8;*/
  margin-bottom: 1rem;
}
.content-texts {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #202020;
  margin-bottom: 1rem;
}

.content-div {
  margin-bottom: 1rem;
}

.link {
  /*color: #0054B0;*/
  color: #04b0b8;
}

</style>
